<template>
  <v-app>
    <v-app-bar app>
      <v-app-bar-nav-icon @click="navDrawer = !navDrawer" class="ml-1">
        <v-avatar size="40" color="white">
          <v-img
            contain
            max-height="30"
            src="https://pickupgoat.com/img/icons/safari-pinned-tab.svg"
            alt="PickupGOAT"
          ></v-img>
        </v-avatar>
      </v-app-bar-nav-icon>
      <v-toolbar-title
        v-if="$vuetify.breakpoint.name != 'xs'"
        style="cursor: pointer"
        @click="$router.push('/')"
        class="grow"
      >
        PickupGOAT
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn text :to="{ name: 'Account' }" v-if="isAuthenticated">
        <v-avatar size="28px" class="mr-3"
          ><v-gravatar :email="user.email"
        /></v-avatar>
        {{ user.first_name || user.email }}
      </v-btn>
      <v-btn text :to="{ name: 'Login' }" v-else>Login</v-btn>
    </v-app-bar>

    <v-navigation-drawer absolute clipped v-model="navDrawer">
      <v-list>
        <v-list-item class="pb-2">//</v-list-item>
        <v-list-item
          v-for="item in navItems"
          :key="item.title"
          link
          exact
          :to="item.href"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-container> </v-container>
      <template v-slot:append>
        <default-settings-theme />
        <div class="pa-2">
          <v-btn block to="/logout">Logout</v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <router-view></router-view>

        <v-snackbar bottom :value="updateExists" :timeout="-1" color="primary">
          An update is available
          <v-btn text @click="refreshApp">
            Update
          </v-btn>
        </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import update from "./mixins/update";
import router from "@/router";
import DefaultSettingsTheme from "./components/settings/Theme.vue";
import { get, sync, call } from "vuex-pathify";

export default {
  name: "App",
  components: { DefaultSettingsTheme },

  data: () => ({
    navDrawer: false,
    navItems: [
      {
        title: "Home",
        href: router.resolve({ name: "Home" }).href,
        icon: "mdi-home"
      }
      // {
      //   title: "Groups",
      //   href: router.resolve({ name: "Groups" }).href,
      //   icon: "mdi-account-group"
      // }x
    ]
  }),
  mixins: [update],
  created() {
    this.initializeAuth();
  },
  computed: {
    user: sync("auth/user"),
    isAuthenticated: get("auth/isAuthenticated")
  },
  methods: {
    initializeAuth: call("auth/initialize")
  }
};
</script>

<style>
.v-navigation-drawer--absolute {
  position: fixed !important;
  z-index: 2 !important;
  height: 100vh !important;
}

/* Dark mode link color */
.theme--dark .comment a {
  color: #b39ddb;
}
</style>
