<template>
  <OneColumnPage>
    <v-card class="elevation-12 pb-2">
      <v-toolbar color="primary" flat>
        <v-toolbar-title>{{
          group_id ? "Edit Group" : "Create A New Group"
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form @submit.prevent="submit">
          <v-text-field
            v-model="localInputs.title"
            id="title"
            label="Title"
            prepend-icon="mdi-label"
            type="text"
            :error="formErrors.title"
            :error-messages="formErrors.title"
          />
          <v-textarea
            v-model="localInputs.description"
            id="description"
            label="Description"
            prepend-icon="mdi-card-text-outline"
            type="textarea"
            :error="formErrors.description"
            :error-messages="formErrors.description"
          />
        </v-form>
        <v-alert
          outlined
          type="error"
          v-for="(error, i) in formErrors.non_field_errors"
          :key="i"
        >
          {{ error }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :loading="requestLoading"
          color="primary"
          @click="submit"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </OneColumnPage>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "GroupForm",
  props: ["group_id"],
  data() {
    return {
      localInputs: {
        id: "",
        title: "",
        description: ""
      }
    };
  },
  computed: {
    ...mapState({
      storeGroup: state => state.groups.group,
      formErrors: state =>
        state.groups.error ? state.groups.error.response.data : {}
    }),
    ...mapState("groups", ["error", "requestLoading"])
  },
  methods: {
    ...mapActions("groups", ["saveGroup", "fetchGroupDetail"]),
    submit() {
      this.saveGroup(this.localInputs);
    }
  },
  watch: {
    storeGroup: {
      handler(newVal) {
        if (this.group_id && newVal) {
          this.localInputs = {
            id: this.group_id,
            title: newVal.title || "",
            description: newVal.description || ""
          };
        }
      },
      immediate: true
    }
  },
  created() {
    if (this.group_id) {
      this.fetchGroupDetail(this.group_id);
    } else {
      // Reset form for new group
      this.localInputs = {
        id: "",
        title: "",
        description: ""
      };
    }
  }
};
</script>
