import comments from "@/api/comments";
import {
  REQUEST_START,
  FETCH_COMMENTS,
  REQUEST_ERROR,
  COMMENT_SAVED,
  SET_COMMENTS,
  RESET_COMMENTS,
  COMMENT_DELETED
} from "./types";

const getDefaultState = () => {
  return {
    object_type: "",
    object_pk: "",
    url: "",
    comments: {},
    loadingComments: false,
    requestLoading: false,
    error: false,
    largestCommentId: 0
  };
};

const state = getDefaultState();

const getters = {};

const actions = {
  resetComments({ commit }) {
    commit(RESET_COMMENTS);
  },
  fetchComments(
    { commit, state },
    { object_type, object_pk, url, loadOlder = false }
  ) {
    commit(FETCH_COMMENTS);

    let fetchUrl = url;
    if (
      !url ||
      state.object_type !== object_type ||
      state.object_pk !== object_pk
    ) {
      try {
        const baseUrl = process.env.VUE_APP_API_URL;
        fetchUrl = `${baseUrl}/comments/?object_type=${object_type}&object_pk=${object_pk}`;
      } catch (error) {
        console.error("Failed to construct URL:", error);
        return;
      }
    }

    return comments
      .fetchComments(fetchUrl)
      .then(({ data }) => {
        commit(SET_COMMENTS, {
          data,
          object_type,
          object_pk,
          url: fetchUrl,
          loadOlder
        });
        commit("SET_HAS_OLDER_COMMENTS", data.has_older_comments);
      })
      .catch((error) => {
        console.error("Error fetching comments:", error);
      });
  },
  async saveComment({ commit }, { inputs, images }) {
    commit(REQUEST_START);
    try {
      // Upload images first if any
      const uploadedImageIds = [];
      if (images.length) {
        const imageUploadPromises = images.map((image) => {
          const formData = new FormData();
          formData.append("image", image);
          return comments.uploadImage(formData);
        });

        // Wait for all images to be uploaded and collect their IDs
        const responses = await Promise.all(imageUploadPromises);
        uploadedImageIds.push(...responses.map((response) => response.data.id));
      }

      // Create the comment with image IDs
      const { data: comment } = await comments.createComment({
        ...inputs,
        image_ids: uploadedImageIds
      });

      commit(COMMENT_SAVED, comment);
      return comment;
    } catch (error) {
      commit(REQUEST_ERROR, error);
      throw error;
    }
  },
  deleteComment({ commit }, comment) {
    commit(REQUEST_START);
    return comments
      .deleteComment(comment)
      .then(() => commit(COMMENT_DELETED, comment))
      .catch((error) => {
        commit(REQUEST_ERROR, error);
      });
  },
  loadOlderComments({ dispatch, state }, { object_type, object_pk }) {
    const currentUrl = state.comments.url;
    if (!currentUrl) {
      console.error("Current comments URL is not available");
      return;
    }

    try {
      const url = new URL(currentUrl);
      url.searchParams.set("load_all", "true");

      dispatch("fetchComments", {
        object_type,
        object_pk,
        url: url.toString(),
        loadOlder: true
      });
    } catch (error) {
      console.error("Failed to construct load all URL:", error);
    }
  },
  async uploadImage({ commit }, { commentId, formData }) {
    try {
      const response = await comments.uploadImage(commentId, formData);
      return response.data;
    } catch (error) {
      commit(REQUEST_ERROR, error);
      throw error;
    }
  }
};

const mutations = {
  [RESET_COMMENTS](state) {
    Object.assign(state, getDefaultState());
  },
  [SET_COMMENTS](state, { data, object_type, object_pk, url, loadOlder }) {
    state.loadingComments = false;

    if (
      state.object_type == object_type &&
      state.object_pk == object_pk &&
      !!state.comments &&
      data.count > state.comments.count
    ) {
      // Highlight new comments based on the largest comment ID seen so far
      data.results.forEach((comment) => {
        if (comment.id > state.largestCommentId) {
          comment.new = true;
        }
      });
    }

    // Update the largest comment ID seen
    const maxIdInResults = Math.max(
      ...data.results.map((comment) => comment.id),
      state.largestCommentId
    );
    state.largestCommentId = maxIdInResults;

    state.object_type = object_type;
    state.object_pk = object_pk;
    state.comments = data;
    state.comments.url = url;
  },
  [FETCH_COMMENTS](state) {
    state.loadingComments = true;
    state.error = false;
  },
  [REQUEST_START](state) {
    state.requestLoading = true;
    state.error = false;
  },
  [REQUEST_ERROR](state, error) {
    state.loadingComments = false;
    state.requestLoading = false;
    state.error = error;
  },
  [COMMENT_DELETED](state, comment) {
    state.requestLoading = false;
    const commentList = [...state.comments.results];
    const index = commentList.indexOf(comment);
    if (index > -1) {
      commentList.splice(index, 1);
      state.comments.results = commentList;
    }
  },
  [COMMENT_SAVED](state, comment) {
    state.requestLoading = false;
    state.error = false;
    comment.new = true;
    state.comments.results.unshift(comment);
    state.comments.count++;
  },
  SET_HAS_OLDER_COMMENTS(state, hasOlderComments) {
    state.comments.has_older_comments = hasOlderComments;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
