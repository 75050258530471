import session from "./session";

// MAKE SURE URLS ALWAYS END IN '/'
export default {
  fetchComments(url) {
    return session.get(url);
  },
  createComment({ comment, object_type, object_pk, image_ids }) {
    return session.post(`${process.env.VUE_APP_API_URL}/comments/`, {
      comment,
      object_type,
      object_pk,
      image_ids
    });
  },
  updateComment(comment) {
    return session.put(
      `${process.env.VUE_APP_API_URL}/comments/${comment.id}/`,
      comment
    );
  },
  deleteComment(comment) {
    return session.delete(
      `${process.env.VUE_APP_API_URL}/comments/${comment.id}/`
    );
  },
  uploadImage(formData) {
    return session.post(
      `${process.env.VUE_APP_API_URL}/comments/upload_image/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
  }
};
