import games from "@/api/games";
import {
  SET_GAMES,
  SET_GAME,
  RESET_GAME,
  GAME_SAVED,
  REQUEST_START,
  REQUEST_ERROR,
  SIGNUP_START,
  SIGNUP_COMPLETE
} from "./types";
import router from "@/router";
import groupBy from "lodash/groupBy";

const getDefaultState = () => {
  return {
    games: [],
    game: {
      title: "",
      description: "",
      location: "",
      date: "",
      start: "",
      end: "",
      recurrences: "",
      signups: []
    },
    requestLoading: false,
    signupLoading: false,
    error: false
  };
};

const state = getDefaultState();

const statusOrder = ["confirmed", "waitlisted", "maybe", "out"];
const orderedArray = (data) =>
  Object.keys(data)
    .sort((a, b) => statusOrder.indexOf(a) - statusOrder.indexOf(b))
    .map((sortedKey) => ({
      status: sortedKey,
      signups: data[sortedKey]
    }));

const getters = {
  allGames: (state) => state.games,
  signupGroups: (state) => orderedArray(groupBy(state.game.signups, "status")),
  signedUpCount: (state) =>
    state.game.signups.filter((s) => s.status === "confirmed").length,
  waitlistedCount: (state) =>
    state.game.signups.filter((s) => s.status === "waitlisted").length,
  userSignups: (state, getters, rootState) =>
    state.game.signups.filter((s) => s.user.id === rootState.auth.user.pk),
  userIsSignedUp: (state, getters, rootState) =>
    getters.userSignups && getters.userSignups[0].status != "out"
};

const actions = {
  fetchGames({ commit }) {
    commit(REQUEST_START);
    return games.fetchGames().then(({ data }) => commit(SET_GAMES, data));
  },
  fetchGameDetail({ commit, rootState }, game_id) {
    commit(REQUEST_START);
    return games
      .fetchGameDetail(game_id)
      .then(({ data }) => commit(SET_GAME, { game: data, rootState }))
      .catch((error) => commit(REQUEST_ERROR, error));
  },
  resetGame({ commit }) {
    commit(RESET_GAME);
  },
  saveGame({ commit }, game) {
    if (game.id) {
      commit(REQUEST_START);
      return games
        .updateGame(game)
        .then(({ data }) => commit(GAME_SAVED, data))
        .catch((error) => commit(REQUEST_ERROR, error));
    } else {
      commit(REQUEST_START);
      return games
        .createGame(game)
        .then(({ data }) => commit(GAME_SAVED, data))
        .catch((error) => commit(REQUEST_ERROR, error));
    }
  },
  sendSignup({ commit, rootState }, { game, status, user, signup }) {
    commit(SIGNUP_START);
    return games
      .signup(game, status, user, signup)
      .then(({ data }) => {
        commit(SIGNUP_COMPLETE, { signup: data.result, rootState });
      })
      .catch((error) => commit(REQUEST_ERROR, error));
  },
  setGuestName({ commit, rootState }, signup) {
    commit(SIGNUP_START);
    return games
      .setGuestName(signup)
      .then(({ data }) => {
        commit(SIGNUP_COMPLETE, { signup: data.result, rootState });
      })
      .catch((error) => commit(REQUEST_ERROR, error));
  },
  async reorderWaitlist({ commit, rootState }, { gameId, signupIds }) {
    commit(REQUEST_START);
    try {
      await games.reorderWaitlist(gameId, signupIds);
      // Refresh game data to get updated order
      return games
        .fetchGameDetail(gameId)
        .then(({ data }) => commit(SET_GAME, { game: data, rootState }));
    } catch (error) {
      commit(REQUEST_ERROR, error);
      throw error;
    }
  }
};

const mutations = {
  [SET_GAMES](state, games) {
    state.requestLoading = false;
    state.games = games;
  },
  [SET_GAME](state, { game, rootState }) {
    state.requestLoading = false;
    state.game = game;
    if (!rootState.groups.group.id)
      this.dispatch("groups/fetchGroupDetail", game.group.id);
  },
  [RESET_GAME](state, game) {
    state.game = getDefaultState().game;
  },
  [REQUEST_START](state) {
    state.requestLoading = true;
    state.error = false;
  },
  [REQUEST_ERROR](state, error) {
    state.requestLoading = false;
    state.signupLoading = false;
    state.error = error;
  },
  [GAME_SAVED](state, game) {
    state.requestLoading = false;
    state.error = false;
    !game.abstract
      ? router.push({
          name: "GameDetail",
          params: { game_id: game.id, group_id: game.group.id }
        })
      : router.currentRoute.query.instance
      ? router.push({
          name: "GameDetail",
          params: {
            game_id: router.currentRoute.query.instance,
            group_id: game.group.id
          }
        })
      : router.push({
          name: "GroupDetail",
          params: {
            group_id: game.group
          }
        });
  },
  [SIGNUP_START](state) {
    state.signupLoading = true;
    state.error = false;
  },
  [SIGNUP_COMPLETE](state, { signup, rootState }) {
    state.signupLoading = false;
    state.error = false;

    // remove from not replied list
    if (state.game) {
      this.dispatch("games/fetchGameDetail", state.game.id);
      // if (state.game.not_replied) {
      //   let index1 = state.game.not_replied.findIndex(
      //     (s) => s.user.id === signup.user.id
      //   );
      //   index1 > -1 && state.game.not_replied.splice(index1, 1);
      // }

      // // insert signup
      // let index = state.game.signups.findIndex((v) => v.id === signup.id);
      // index > -1 && state.game.signups.splice(index, 1);
      // state.game.signups.unshift(signup);
      // if (signup.user.id === rootState.auth.user.pk && !signup.guest)
      //   state.game.user_status = signup.status;

      // // guest handling
      // if (signup.guest) {
      //   if (signup.status == "out") {  // guest removed, remove from list

      //     let index2 = state.game.signups.findIndex(s => s.id === signup.id);
      //     index2 > -1 && state.game.signups.splice(index2, 1);

      //     // remove from user_guests
      //     let index3 = state.game.user_guests.findIndex(s => s.id === signup.id);
      //     index3 > -1 && state.game.user_guests.splice(index3, 1);

      //     // add back to guests_left
      //     state.game.user_guests_left++;
      //   } else {
      //     // guest added

      //     // add to user_guests
      //     state.game.user_guests.push(signup);
      //     // remove from guests_left
      //     state.game.user_guests_left--;

      //   }
      // }
    }

    // reload group (for dropdown actions on group page)
    if (signup.group_id)
      this.dispatch("groups/fetchGroupDetail", signup.group_id);

    // could fetch the whole game again, but that's not super smooth
    // this.dispatch("games/fetchGameDetail", signup.game);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
