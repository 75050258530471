<template>
  <v-card class="pb-1" :loading="requestLoading || loadingComments">
    <v-skeleton-loader type="list-item" class="mx-auto" :loading="loading">
      <v-card-subtitle class="overline">Comments</v-card-subtitle>
      <div class="px-4 ">
        <v-form ref="form" @submit.prevent="submit">
          <v-textarea
            v-model="inputs.comment"
            id="comment"
            label="Write a comment..."
            type="textarea"
            auto-grow
            hint="Press enter to submit, shift+enter for new line. Drag and drop images or click to upload."
            rows="2"
            :error="!!formErrors.comment"
            :error-messages="formErrors.comment"
            @keydown.enter.exact.prevent
            @keyup.enter.exact="send"
            @drop.prevent="handleDrop"
            @dragover.prevent
          >
            <template v-slot:prepend
              ><v-avatar size="40px" color="grey" class="mr-3"
                ><v-gravatar :email="user.email"/></v-avatar
            ></template>
            <template v-slot:append>
              <v-btn icon @click="triggerImageUpload">
                <v-icon>mdi-image</v-icon>
              </v-btn>
              <input
                type="file"
                ref="imageInput"
                accept="image/*"
                style="display: none"
                @change="handleImageSelect"
                multiple
              />
            </template>
          </v-textarea>
          <div v-if="uploadedImages.length" class="d-flex flex-wrap">
            <v-chip
              v-for="(image, index) in uploadedImages"
              :key="index"
              class="ma-1"
              close
              @click:close="removeImage(index)"
            >
              {{ image.name }}
            </v-chip>
          </div>
          <div class="d-flex justify-space-between mb-2">
            <v-spacer></v-spacer>
            <v-slide-y-transition>
              <v-btn
                color="default"
                @click="send"
                :loading="requestLoading"
                :disabled="!!!inputs.comment"
                >Submit</v-btn
              >
            </v-slide-y-transition>
          </div>
        </v-form>
      </div>

      <transition-group name="list-animation">
        <v-hover
          v-for="comment in comments.results"
          :key="comment.id"
          class="list-animation-item"
        >
          <template v-slot:default="{ hover }">
            <div
              class="d-flex py-4 pl-4 pr-1 comment"
              :class="comment.new && 'primary'"
              :new="true"
              @mouseenter="markRead"
            >
              <div class="mr-5">
                <v-avatar size="40px" color="grey"
                  ><v-gravatar :email="comment.user.email"
                /></v-avatar>
              </div>
              <div class="flex-grow-1">
                <div class="pr-3">
                  <div class="body-1 comment-text">
                    <div
                      v-html="
                        comment.comment.replace(
                          /(https?:\/\/[^\s]+)/g,
                          '<a href=\'$1\' target=\'_blank\'>$1</a>'
                        )
                      "
                    ></div>
                    <div
                      v-if="comment.images.length"
                      class="comment-images-wrapper d-flex flex-wrap"
                    >
                      <div
                        v-for="image in comment.images"
                        :key="image.id"
                        class="image-container ma-1"
                      >
                        <img
                          :src="image.image"
                          height="200"
                          class="rounded-lg comment-image-preview"
                          @click="openImageDialog(image)"
                          @load="onImageLoad($event, image)"
                          :style="{
                            width: getImageWidth(image) + 'px'
                          }"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="caption font-weight-light text--secondary d-flex justify-space-between"
                  >
                    <div>
                      {{ comment.user.first_name }} {{ comment.user.last_name }}
                    </div>

                    <div>
                      {{ comment.submit_date | moment("ddd, MMM D @ h:mm a") }}
                    </div>
                  </div>
                </div>
              </div>

              <v-fade-transition>
                <v-overlay
                  v-if="hover && comment.user.id == user.pk"
                  absolute
                  color="secondary"
                  class="delete-overlay"
                >
                  <v-btn
                    fab
                    color="error"
                    class="mx-2"
                    @click.stop="askDeleteComment(comment)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-overlay>
              </v-fade-transition>
            </div>
          </template>
        </v-hover>
      </transition-group>
      <v-card-actions
        v-if="comments.next || comments.previous || comments.has_older_comments"
      >
        <v-spacer></v-spacer>
        <span class="caption text--secondary mr-2">
          showing {{ comments.offset + 1 }}-{{
            comments.offset + comments.results.length
          }}
          of {{ comments.count }}</span
        >
        <v-btn
          v-if="comments.previous"
          @click="fetchComments({ url: comments.previous })"
          ><v-icon>mdi-chevron-left</v-icon></v-btn
        >
        <v-btn
          v-if="comments.next"
          @click="fetchComments({ url: comments.next })"
          ><v-icon>mdi-chevron-right</v-icon></v-btn
        >
        <v-btn
          v-if="comments.has_older_comments && !comments.next"
          @click="handleLoadOlderComments"
          color="primary"
        >
          Load Older Comments
        </v-btn>
      </v-card-actions>
    </v-skeleton-loader>

    <v-dialog v-model="dialogDelete" max-width="290">
      <v-card>
        <v-card-title class="headline">
          Delete Comment?
        </v-card-title>

        <v-card-text>
          Delete this comment?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogDeleteHide">
            No
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="confirmDeleteComment(comment_to_delete)"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <image-dialog
      v-if="showImageDialog"
      :images="allImages"
      :show-image-dialog="showImageDialog"
      :initial-index="currentImageIndex"
      @close="showImageDialog = false"
    />
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "Comments",
  data() {
    return {
      loading: true,
      dialogDelete: false,
      comment_to_delete: null,
      inputs: {
        comment: "",
        object_type: this.object_type,
        object_pk: this.object_pk
      },
      uploadedImages: [],
      imageDimensions: {},
      showImageDialog: false,
      currentImageIndex: 0,
      allImages: [],
      imageSizeError: false
    };
  },
  props: ["object_type", "object_pk"],
  methods: {
    ...mapActions("comments", [
      "resetComments",
      "fetchComments",
      "saveComment",
      "deleteComment",
      "loadOlderComments"
    ]),
    reset() {
      this.$refs.form.reset();
    },
    newline() {
      this.inputs.comment = `${this.inputs.comment}\n`;
    },
    send() {
      this.saveComment(this.inputs)
        .then(this.reset)
        .catch((err) => console.log(err));
    },
    updateCommentsList() {
      this.fetchComments({
        object_type: this.object_type,
        object_pk: this.object_pk,
        url:
          this.object_type === this.comments.object_type &&
          this.object_pk === this.comments.object_pk
            ? this.comments.url
            : null
      }).then(() => (this.loading = false));
    },
    markRead(event) {
      event.target.classList.remove("primary");
    },
    askDeleteComment(comment) {
      this.comment_to_delete = comment;
      this.dialogDelete = true;
    },
    confirmDeleteComment(comment) {
      this.deleteComment(comment).then(this.dialogDeleteHide);
    },
    dialogDeleteHide() {
      this.comment_to_delete = null;
      this.dialogDelete = false;
    },
    handleLoadOlderComments() {
      this.loadOlderComments({
        object_type: this.object_type,
        object_pk: this.object_pk
      });
    },
    triggerImageUpload() {
      this.$refs.imageInput.click();
    },

    handleImageSelect(event) {
      const files = Array.from(event.target.files);
      this.processImages(files);
    },

    handleDrop(event) {
      const files = Array.from(event.dataTransfer.files).filter((file) =>
        file.type.startsWith("image/")
      );
      this.processImages(files);
    },

    async processImages(files) {
      this.imageSizeError = false;
      for (const file of files) {
        if (file.size > 25000000) {
          // 25MB limit
          this.imageSizeError = true;

          // Remove the error after 4 seconds
          setTimeout(() => {
            this.imageSizeError = false;
          }, 4000);

          continue;
        }
        this.uploadedImages.push(file);
      }
    },

    removeImage(index) {
      this.uploadedImages.splice(index, 1);
    },

    async send() {
      try {
        // Pass both inputs and images to the saveComment action
        await this.$store.dispatch("comments/saveComment", {
          inputs: this.inputs,
          images: this.uploadedImages
        });

        // Reset the form and clear uploaded images
        this.reset();
        this.uploadedImages = [];
      } catch (err) {
        console.error(err);
      }
    },

    openImageDialog(clickedImage) {
      this.allImages = this.comments.results.reduce((acc, comment) => {
        return acc.concat(comment.images);
      }, []);

      this.currentImageIndex = this.allImages.findIndex(
        (img) => img.id === clickedImage.id
      );

      this.showImageDialog = true;
    },

    getImageWidth(image) {
      if (this.imageDimensions[image.id]) {
        const { width, height } = this.imageDimensions[image.id];
        return Math.round((width / height) * 200);
      }
      return 200; // Default width before load
    },

    onImageLoad(event, image) {
      const img = event.target;
      this.$set(this.imageDimensions, image.id, {
        width: img.naturalWidth,
        height: img.naturalHeight
      });
    }
  },
  computed: {
    ...mapState("comments", [
      "comments",
      "requestLoading",
      "loadingComments",
      "error"
    ]),
    ...mapState("auth", ["user"]),
    formErrors() {
      const errors = {};
      if (this.imageSizeError) {
        errors.comment = ["Image size must be less than 5MB"];
      } else if (this.comments.error) {
        if (this.comments.error.response.status === 500) {
          errors.comment = ["Error: Comment not saved. Try again."];
        } else {
          try {
            console.log(this.comments.error.response.data);
            return this.comments.error.response.data;
          } catch (err) {
            console.log("Comment not saved.");
            errors.comment = ["Error: Comment not saved."];
          }
        }
      }
      return errors;
    }
  },
  created: function() {},
  mounted() {
    this.resetComments().then(() => {
      this.updateCommentsList();
      this.timer = setInterval(() => {
        if (document.visibilityState === "visible") {
          this.updateCommentsList();
        }
      }, 20000);
    });
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        this.updateCommentsList();
      }
    });
  },
  beforeDestroy() {
    clearInterval(this.timer);
    // this.resetComments();
  }
};
</script>

<style scoped>
.comment-text {
  white-space: pre-line;
}
.comment {
  position: relative;
  transition: background-color 1s ease;
}

.list-animation-item {
  transition: all 0.4s;
}
.list-animation-enter {
  opacity: 0;
  transform: translateY(-80px);
}
.list-animation-leave-to
/* .list-animation-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(-600px);
}
.list-animation-leave-active {
  position: absolute;
}
.delete-overlay {
  width: 1px !important;
  height: 1px !important;
  right: 0;
  top: 50%;
  left: auto !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: transparent !important;
}

.comment-image-preview {
  background-color: rgba(0, 0, 0, 0.03);
  height: 200px;
  width: auto;
}

.image-container {
  height: 200px;
  display: inline-flex;
  align-items: center;
}

.comment-images-wrapper {
  margin: 8px 0;
  gap: 8px;
}
</style>
