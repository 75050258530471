<template>
  <v-dialog v-model="dialogVisible" max-width="90vw">
    <v-card>
      <v-carousel
        v-model="activeIndex"
        hide-delimiters
        height="auto"
        @swipe="onSwipe"
      >
        <v-carousel-item v-for="image in images" :key="image.id">
          <v-img
            :src="image.image"
            class="d-block mx-auto"
            max-height="80vh"
            contain
          ></v-img>
        </v-carousel-item>
      </v-carousel>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeDialog">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      required: true
    },
    showImageDialog: {
      type: Boolean,
      required: true
    },
    initialIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      dialogVisible: this.showImageDialog,
      activeIndex: this.initialIndex
    };
  },
  watch: {
    showImageDialog(newVal) {
      this.dialogVisible = newVal;
    },
    dialogVisible(newVal) {
      if (!newVal) {
        this.closeDialog();
      }
    },
    initialIndex(newVal) {
      this.activeIndex = newVal;
    }
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    onSwipe(direction) {
      if (direction === "left") {
        this.nextImage();
      } else if (direction === "right") {
        this.prevImage();
      }
    },
    nextImage() {
      if (this.activeIndex < this.images.length - 1) {
        this.activeIndex++;
      }
    },
    prevImage() {
      if (this.activeIndex > 0) {
        this.activeIndex--;
      }
    },
    handleKeydown(event) {
      if (event.key === "ArrowRight") {
        this.nextImage();
      } else if (event.key === "ArrowLeft") {
        this.prevImage();
      }
    }
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeydown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeydown);
  }
};
</script>
